<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="api ? api.isLoading : false"
      :search="search"
      :custom-filter="customFilter"
      :mobile-breakpoint="bread == 'read' ? 9999 : 600"
      :hide-default-footer="true"
      :disable-sort="true"
      :disable-filtering="isSearchable ? isSearchable : false"
      :disable-pagination="true"
      :single-expand="true"
      :expanded.sync="expanded"
      :show-expand="expandable ? expandable : false"
      :no-data-text="$t('api.response.no_data')"
      :no-results-text="$t('api.response.no_data')"
    >
      <template v-slot:item.index="{ item, index }">
        <div>{{ index + 1 }}</div>
      </template>
      <!--BOC-->
      <template v-slot:item.imageUrl="{ item }">
        <v-img
          contain
          max-width="50"
          max-height="30"
          :src="item.imageUrl" 
        ></v-img>
      </template>
      <template v-slot:item.drawing="{ item }">
        <v-img
        class="ma-1"
          contain
          max-width="80"
          max-height="80"
          :src="item.imageUrl" 
        ></v-img>
      </template>
      <template v-slot:item.images="{ item }">
        <div class="d-flex" v-if="item.images">
          <template v-for="img in Object.entries(JSON.parse(item.images))">
            <v-tooltip 
              :key="img[1].url"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  contain
                  max-width="50"
                  max-height="30"
                  :src="img[1].url" 
                  v-bind="attrs"
                  v-on="on"
                ></v-img>
              </template>
              <span>{{$_ucfirst(img[0])}}</span>
            </v-tooltip>
          </template>
        </div>
      </template>
      <!--EOC-->
      <!--BOC:[dataset related columns]-->
      <template v-slot:item.schoolYear="{ item }">
        <div v-if="item.schoolYear">
          {{ $_.find(datasetSchoolYears, { id: item.schoolYear }).name }}
        </div>
        <div v-else>
          -
        </div>
      </template>
      <!--EOC-->
      <!--BOC:[external model columns]-->
      <template v-slot:item.Syllabus="{ item }">
        <router-link
          class="text-decoration-none"
          :to="{ name: 'PageMainDiscover' }"
          >{{ item.syllabusShortname }}</router-link
        >
      </template>
      <!--EOC-->
      <!--BOC:[model columns]-->
      <template v-for="model in models" v-slot:[`item.${model}`]="{ item }">
        <DataTableColumnModel
          :key="model"
          :objectKey="model"
          :item="item"
          :role="role"
        ></DataTableColumnModel>
      </template>
      <!--EOC-->
      <!--BOC:[model specific prop columns]-->
       <template v-slot:item.eventPassword="{ item }">
          <Password :callbackSuccess="callbackSuccess"  @refetch="refetchInfo" :value="item.password" :eventId="item.id"/>
      </template>
      <template v-slot:item.achievementProgressTimestampCompleted="{ item }">
        <span v-if="item.timestampCompleted">{{
          $moment(item.timestampCompleted).fromNow()
        }}</span
        ><span v-else>-</span>
      </template>
      <template v-slot:item.achievementProgressTimestampClaimed="{ item }">
        <span v-if="item.timestampClaimed">{{
          $moment(item.timestampClaimed).fromNow()
        }}</span
        ><span v-else>-</span>
      </template>
      <template v-slot:item.month="{ item }">
        <span v-if="item.month">{{
         $moment().month(item.month - 1).format('MMMM')
        }}</span
        ><span v-else>-</span>
      </template>
      <template v-slot:item.achievementProgressProgress="{ item }">
        <v-progress-linear
          height="25"
          :value="(item.current / item.target) * 100"
          >{{ (item.current / item.target) * 100 }}%</v-progress-linear
        >
      </template>
      <template v-slot:item.schoolMessageInvitation="{ item }">
        <DataTableColumnMessage
          object="schoolMessageInvitation"
           :title='$t("action.send_invitation")'
          :item="item"
        ></DataTableColumnMessage>
      </template>
      <template v-slot:item.classroomMessageInvitation="{ item }">
        <DataTableColumnMessage
          object="classroomMessageInvitation"
         :title='$t("action.send_invitation")'
          :item="item"
        ></DataTableColumnMessage>
      </template>
      <template v-slot:item.eventMessageInvitation="{ item }">
        <DataTableColumnMessage
          object="eventMessageInvitation"
           :title='$t("action.send_invitation")'
          :item="item"
        ></DataTableColumnMessage>
      </template>
      <template v-slot:item.festivalMessageInvitation="{ item }">
        <FestivalMessage
          :item="item"
        ></FestivalMessage>
      </template>
       <template v-slot:item.eventMessageNotice="{ item }">
        <Notice
          :item="item"
        ></Notice>
        </template>
        <template v-slot:item.festivalMessageNotice="{ item }">
        <FestivalNotice
          :item="item"
        ></FestivalNotice>
        </template>
         <template v-slot:item.eventPass="{ item }">
        <Pass
          :item="item"
        ></Pass>
      </template>
       <template v-slot:item.eventMessageScoreboard="{ item }">
        <DataTableColumnMessage
          object="eventMessageScoreboard"
          :title='$t("view.PageBackendEvent.send_scoreboard")'
          :item="item"
          v-if="!reload"
        ></DataTableColumnMessage>
      </template>
      <template v-slot:item.eventStatus="{ item }">
        <v-chip
          v-if="
            $moment() >= $moment(item.timestampStart) &&
            $moment() < $moment(item.timestampEnd)
          "
          small
          dark
          color="green"
          >{{$t("model.value.status.ongoing")}}</v-chip
        >
        <v-chip
          v-else-if="$moment(item.timestampEnd) < $moment()"
          small
          dark
          color="gray"
          >{{$t("model.value.status.past")}}</v-chip
        >
        <v-chip v-else small dark color="orange">{{$t("model.value.status.coming")}}</v-chip>
      </template>
      <template v-slot:item.jobStatus="{ item }">
        <v-chip
          v-if="item.status == 'success'"
          small
          dark
          color="green"
          >Success</v-chip
        >
        <v-chip
          v-else-if="item.status == 'stopped'"
          small
          dark
          color="red"
          >Stopped</v-chip
        >
        <v-chip v-else small dark color="orange">Running</v-chip>
      </template>
      <template v-slot:item.isDeleted="{ item }">
        <v-chip
          v-if="item.timestampDeleted"
          small
          dark
          color="red"
          >Yes</v-chip
        >
        <v-chip
          v-else
          small
          dark
          color="green"
          >No</v-chip
        >
      </template>
      <template v-slot:item.applicationStatus="{ item }">
        <v-chip
          v-if="item.status == 'pending'"
          small
          dark
          color="orange"
          >Pending</v-chip
        >
        <v-chip
          v-else-if="item.status == 'approved'"
          small
          dark
          color="green"
          >Approved</v-chip
        >
        <v-chip
          v-else-if="item.status == 'canceled'"
          small
          dark
          color="gray"
          >Canceled</v-chip
        >
        <v-chip v-else small dark color="red">Rejected</v-chip>
      </template>
      <template v-slot:item.submissionAccuracy="{ item }">
        <div>{{ item.accuracy * 100 }}%</div>
      </template>
      <template v-slot:item.submissionStar="{ item }">
        <div>{{ item.star }}★</div>
      </template>
      <template v-slot:item.participantProgress="{ item }">
        <v-progress-linear height="25" :value="item.progress * 100"
          >{{ item.progress * 100 }}%</v-progress-linear
        >
      </template>
      <template v-slot:item.accountSelect="{ item }">
        <DataTableButtonAccountSelect
          :item="item"
          :role="role"
        ></DataTableButtonAccountSelect>
      </template>
       <template v-slot:item.ClassroomName="{ item }">
        <router-link :to="{name:'PageModeratorClassroomRead',params:{id:item.Student.Classroom.id}}" class="text-decoration-none">
          {{ item.Student.Classroom.name }}
      </router-link>
      </template>
        <template v-slot:item.SyllabusName="{ item }">
        <span v-if="item.Syllabus && item.Syllabus.translatableName">{{
          JSON.parse(item.Syllabus.translatableName)[$_getLocale()]
        }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.PositionName="{ item }">
        <span v-if="item.Position && item.Position.translatableName">{{
          JSON.parse(item.Position.translatableName)[$_getLocale()]
        }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.playerMochiId="{ item }">
        <router-link :to="{name:'PageModeratorPlayerRead',params:{id:item.playerId}}" class="text-decoration-none">
          {{ item.mochiId }}
      </router-link>
      </template>
       <template v-slot:item.gameUrl="{ item }">
         <a class="text-decoration-none" target="_blank" :href="urlGame + 'home/?code='+ item.code">
         {{$t("action.click_here")}}

         </a>
      </template>
      <template v-slot:item.icon="{ item }">
            <div> <span><v-icon aria-hidden="false"> {{item.icon}}</v-icon></span> {{item.icon}}</div>
        </template>
        <template v-slot:item.color="{ item }">
           <span class="pa-3"  :class="item.color">
            {{item.color}}
           </span>
        </template>
       <template v-slot:item.logoUrl="{ item }">
         <div >
            <v-img
            v-if="replaceLogoUrl"
              max-height="150"
              max-width="150"
              :src="replaceLogoUrl"
              class="mt-3 ml-auto"
            ></v-img>
        </div>
        <div>
             <v-img   v-if="!replaceLogoUrl"  max-height="150" max-width="150" :src="logoUrl ? logoUrl : item.logoUrl" class="mt-3 ml-auto"></v-img>
        </div>
         <div v-if="(item.logoUrl || logoUrl ) && schoolLogo">
            <v-btn text color="primary" @click="replaceLogo()" class="mt-2" >{{$t('action.replace')}}</v-btn>
            <v-btn text color="primary" @click="downloadImage(item.logoUrl ? item.logoUrl:logoUrl, item.name)" class="mt-2" >Download</v-btn>
         </div>
         <div class="text-right" v-else>
            <FormLogo :model="model"  :callbackSuccess="callbackSuccess" :callbackPreview="callbackPreview" class="pt-3"/>
         </div>
      
      </template>
       <template v-slot:item.prizeImage="{ item }">
            <v-img
            contain
              max-height="80"
              max-width="80"
              :src="item.imageUrl"
            ></v-img>
        </template>
        <template v-slot:item.itemImage="{ item }">
            <v-img
            class="ma-2"
            contain
              max-height="40"
              max-width="40"
              :src="item.Item.images.icon.url"
            ></v-img>
        </template>
        <template v-slot:item.itemPrice="{ item }">
          <div class="d-flex align-center" v-if="item.Item.Currency">
            <span   class="ma-2">{{ item.Item.price }}</span>
            <v-img
            contain
              max-height="20"
              max-width="20"
              :src="item.Item.Currency.images.icon.url"
            ></v-img>
          </div>
       <div v-else>
        -
       </div>
        </template>
        
        <template v-slot:item.buildingImage="{ item }">
            <v-img
            contain
              max-height="80"
              max-width="80"
              :src="item.imageUrl"
            ></v-img>
      </template>
       <template v-slot:item.imageUrl="{ item }">
         <div >
            <v-img
            v-if="replaceLogoUrl"
              max-height="150"
              max-width="150"
              :src="replaceLogoUrl"
              class="mt-3 ml-auto"
            ></v-img>
        </div>
        <div>
             <v-img   v-if="!replaceLogoUrl"  max-height="150" max-width="150" :src="logoUrl ? logoUrl : item.imageUrl" class="mt-3 ml-auto"></v-img>
        </div>
         <div v-if="(item.imageUrl || logoUrl ) && schoolLogo">
            <v-btn text color="primary" @click="replaceLogo()" class="mt-2" >Replace Image</v-btn>
         </div>
         <div class="text-right" v-else>
            <FormLogo :model="model"  :callbackSuccess="callbackSuccess" :callbackPreview="callbackPreview" class="pt-3"/>
         </div>
      
      </template>
      <!--EOC-->
      <!--BOC:[prop related columns]-->
      <template v-slot:item.avatar="{ item }">
        <AAvatar v-if="item.avatar" :avatar="item.avatar"></AAvatar>
        <div v-else>
                      <v-img
                        :max-height="40"
                        :max-width="40"
                        :src="require('@/assets/CS0040_default_F0.png')"
                      ></v-img>
                    </div>
      </template>
      <template v-slot:item.PIN="{ item }">
        <div v-if="item.isEnabledPIN">
           {{ item.PIN }}          
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template v-slot:item.url="{ item }">
        <a class="text-decoration-none" target="_blank" :href="item.url">{{
          item.url
        }}</a>
      </template>
      <template v-slot:item.textEventRule="{ item }">
        <div v-html="item.text"></div>
      </template>
      <template v-slot:item.translatableDescription="{ item }">
        <span v-if="item.translatableDescription">{{
          JSON.parse(item.translatableDescription)[$_getLocale()]
        }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.translatableName="{ item }">
        <span v-if="item.translatableName">{{
          JSON.parse(item.translatableName)[$_getLocale()]
        }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.prizeName="{ item }">
        <span v-if="item.eventPrize">
          <span v-if="item.GamePrize.PrizeType.translatableName">{{
          JSON.parse(item.GamePrize.PrizeType.translatableName)[$_getLocale()]
        }}
        </span>
        <span v-else>-</span>
        </span>
        <span v-else>{{
          JSON.parse(item.PrizeType.translatableName)[$_getLocale()]
        }}</span>
      </template>
      <template v-slot:item.teacherName="{ item }">
        {{ item.User.name }}<span v-if="item.User.accountId == item.Institution.accountId" class="pl-2"
                ><v-chip color="primary" outlined small>Admin</v-chip></span >
      </template>
      <template v-slot:item.festivalPrizeName="{ item }">
          <span v-if="item.prizeName.includes('{')">{{
          JSON.parse(item.prizeName)[$_getLocale()]
        }}
        </span>
        <span v-else>{{
         item.prizeName
        }}</span>
      </template>
          <template v-slot:item.festivalItemName="{ item }">
          <span v-if="item.itemName.includes('{')">{{
          JSON.parse(item.itemName)[$_getLocale()]
        }}
        </span>
        <span v-else>{{
         item.itemName
        }}</span>
      </template>
      <template v-slot:item.enhancedPassword="{ item }">
          <span v-if="item.isEnhancedPassword">{{
          item.password
        }}
        </span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.prizeRewards="{ item }">
         <span v-for="(reward, i) in item.Rewards" :key="i">
           {{ JSON.parse(reward.Item.translatableName)[$_getLocale()] }} x {{ reward.quantity }}
        </span><br>
      </template>
      <template v-slot:item.dateStart="{ item }">
        <span
          >{{$moment(item.timestampStart).format("DD MMM YYYY")}}</span
        >
      </template>
      <template v-slot:item.dateEnd="{ item }">
        <span
          >{{$moment(item.timestampEnd).format("DD MMM YYYY")}}</span
        >
      </template>
      <template v-slot:item.timestampStart="{ item }">
        <span>{{
          $moment(item.timestampStart).format("DD MMM YYYY hh:mm A")
        }}</span>
      </template>
      <template v-slot:item.timestampEnd="{ item }">
        <span>{{
          $moment(item.timestampEnd).format("DD MMM YYYY hh:mm A")
        }}</span>
      </template>
      <template v-slot:item.dateCreated="{ item }">
        <span>{{ $moment(item.timestampCreated).format("YYYY-MM-DD") }}</span>
      </template>
      <template v-slot:item.dateUpdated="{ item }">
        <span>{{ $moment(item.timestampUpdated).format("YYYY-MM-DD") }}</span>
      </template>
      <template v-slot:item.timestampCreated="{ item }">
        <span>{{ $moment(item.timestampCreated).format("YYYY-MM-DD HH:mm:ss") }}</span>
      </template>
      <template v-slot:item.timestampScheduled="{ item }">
        <span>{{ $moment(item.timestampScheduled).format("YYYY-MM-DD HH:mm:ss") }}</span>
      </template>
      <template v-slot:item.timestampInvited="{ item }">
        <span v-if="item.InvitedAccount[0]">{{ $moment(item.InvitedAccount[0].timestampInvited).format("YYYY-MM-DD HH:mm:ss") }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.timestampUpdated="{ item }">
        <span>{{ $moment(item.timestampUpdated).format("YYYY-MM-DD HH:mm:ss") }}</span>
      </template>
      <template v-slot:item.nameWithUrl="{ item }">
       <a v-if="item.websiteUrl" class="text-decoration-none" :href="item.websiteUrl" target="_blank"
          > {{item.name}}</a>
          <span v-else> {{item.name}}</span>
      </template>
       <template v-slot:item.websiteUrl="{ item }">
       <a class="text-decoration-none" :href="item.websiteUrl" target="_blank"
          > {{item.websiteUrl}}</a>
      </template>
      <template v-slot:item.joinedStatus="{ item }">
            <span v-if="(item.User.Account.InvitedAccount[0] && item.User.Account.InvitedAccount[0].timestampPasswordChanged)">{{$moment(item.User.Account.InvitedAccount[0].timestampPasswordChanged).format("DD MMM YYYY hh:mm A")}}</span>
            <span v-else-if="!item.User.Account.InvitedAccount[0]">{{$moment(item.timestampCreated).format("YYYY-MM-DD HH:mm:ss")}} </span>
            <span v-else><v-chip x-small>Pending Invite</v-chip></span>
      </template>
      <template v-slot:item.joinedStatusRead="{ item }">
            <span v-if="(item.User.Account.InvitedAccount[0] && item.User.Account.InvitedAccount[0].timestampPasswordChanged)">{{$moment(item.User.Account.InvitedAccount[0].timestampPasswordChanged).format("DD MMM YYYY hh:mm A")}}</span>
            <span v-else-if="!item.User.Account.InvitedAccount[0]">{{$moment(item.timestampCreated).format("YYYY-MM-DD HH:mm:ss")}} </span>
            <span v-else-if= "item.User && item.User.Account && item.User.Account.InvitedAccount[0] && !item.User.Account.InvitedAccount[0].timestampPasswordChanged"><DataTableColumnInviteMenu
                  :password="item.User.Account.InvitedAccount[0].password"
                  :mobile="item.User.Account.mobile"
                ></DataTableColumnInviteMenu
              ></span>
      </template>
      <template v-slot:item.isActive="{ item }">
        <DataTableColumnBoolean
          :value="item.isActive"
          :textTrue= '$t("model.value.status.active")'
          :textFalse= '$t("model.value.status.inactive")'
        ></DataTableColumnBoolean>
      </template>
      <template v-slot:item.isParticipating ="{ item }">
        <DataTableColumnBoolean
          :value="item.isParticipating"
        ></DataTableColumnBoolean>
      </template>
      <template v-slot:item.isTransferred ="{ item }">
        <DataTableColumnBoolean
          :value="item.isTransferred"
        ></DataTableColumnBoolean>
      </template>
      <template v-slot:item.isEnable="{ item }">
        <DataTableColumnBoolean
          :value="item.enableWalkin"
          :textTrue= '$t("model.value.status.enable")'
          :textFalse= '$t("model.value.status.disable")'
        ></DataTableColumnBoolean>
      </template>
       <template v-slot:item.isExpress="{ item }">
        <DataTableColumnBoolean
          :value="item.isExpress"
          :textTrue= '$t("model.value.status.enable")'
          :textFalse= '$t("model.value.status.disable")'
        ></DataTableColumnBoolean>
      </template>
      <template v-slot:item.isPublished="{ item }">
        <DataTableColumnBoolean
          :value="item.isPublished"
        ></DataTableColumnBoolean>
      </template>
      <template v-slot:item.isSentGift="{ item }">
        <DataTableColumnBoolean
          :value="item.timestampGiftSent ? true : false"
        ></DataTableColumnBoolean>
      </template>
      <template v-slot:item.hasLeagueRewardSent="{ item }">
        <DataTableColumnBoolean
          :value="item.hasLeagueRewardSent ? true : false"
        ></DataTableColumnBoolean>
      </template>
      <!--EOC-->
      <!--BOC:[actions]-->
      <template v-slot:item.actionView="{ item }">
          <v-col class="text-right">
        <router-link
          v-if="modelParent && !modelGrandParent"
          class="text-decoration-none font-weight-medium"
          :to="
            $_getRouteChildRead(
              role,
              model.key,
              item.id,
              item,
              modelParent.key,
              parent.id,
              parent
            )
          "
          >{{$t("action.view")}}</router-link
        >
        <router-link
          v-else-if="modelGrandParent"
          class="text-decoration-none font-weight-medium"
          :to="
            $_getRouteGrandChildRead(
              role,
              model.key,
              item.id,
              item,
              modelParent.key,
              parent.id,
              parent,
              modelGrandParent.key,
              grandParent.id,
              grandParent
            )
          "
          >{{$t("action.view")}}</router-link
        >
        <router-link
          v-else
          class="text-decoration-none font-weight-medium"
          :to="$_getRouteRead(role, model.key, item.id, item)"
          >{{$t("action.view")}}</router-link
        >
         </v-col>
      </template>
      <template v-slot:item.scoreboard="{ item }">
          <v-col class="text-right">
        <router-link
         
          class="text-decoration-none font-weight-medium"
          :to="{ name: 'PageMainEventScoreboard', params: { eventCode: item.code } }"
          target="_blank"
          > {{$t("action.scoreboard")}}</router-link
        >
         </v-col>
      </template>
      <template v-slot:item.institutionScoreboard="{ item }">
          <v-col class="text-right">
        <router-link
         
          class="text-decoration-none font-weight-medium"
          :to="{ name: 'PageMainEventScoreboard', params: { eventCode: item.code }, query:{c: parent.code} }"
          target="_blank"
          > {{$t("action.scoreboard")}}</router-link
        >
         </v-col>
      </template>
      <template v-slot:item.actionTrashed="{ item }">
        <v-btn
          text
          color="error"
          class="mr-2"
          @click="$refs.dialogDestroy.open(item)"
          >DESTROY</v-btn
        >
        <v-btn
          text
          color="success"
          class="mr-2"
          @click="$refs.dialogRestore.open(item)"
          >RESTORE</v-btn
        >
      </template>
      <template v-slot:item.delete="{ item }" >
        <v-col class="text-right">
          
          <span
            text
            class="red--text remove font-weight-medium"
            @click="$refs.dialogSchoolYearDelete.open(item)"
            >{{$t("action.remove")}}</span
          >
        </v-col>
      </template>
      <!--EOC-->

      <!--BOD:[expanded]-->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-3">
          {{ item.data }}
        </td>
      </template>
      <!--EOC-->
    </v-data-table>
    <!--BOD:[destroy]-->
    <DataTableDialogDestroy
      ref="dialogDestroy"
      :role="role"
      :model="model"
    ></DataTableDialogDestroy>
    <DataTableDialogDelete
      ref="dialogDelete"
      :role="role"
      :model="model"
      @refetch="refetchClassroom"
    ></DataTableDialogDelete>
    <DataTableDialogDeleteSchoolYear
      ref="dialogSchoolYearDelete"
      :role="role"
      :model="model"
      @refetch="refetchSchoolYear"
    ></DataTableDialogDeleteSchoolYear>
    <!--EOC-->
    <!--BOD:[restore]-->
    <DataTableDialogRestore
      ref="dialogRestore"
      :role="role"
      :model="model"
    ></DataTableDialogRestore>
    <!--EOC-->
  </div>
</template>

<script>
//BOD:[model]
import models from "@/models";
//EOC
import datasetSchoolYears from "@/datasets/schoolYear";
import DataTableButtonAccountSelect from "@/components/Bread/DataTableButtonAccountSelect";
import DataTableColumnInviteMenu from "@/components/Bread/DataTableColumnInviteMenu";
import DataTableColumnBoolean from "@/components/Bread/DataTableColumnBoolean";
import DataTableColumnMessage from "@/components/Bread/DataTableColumnMessage";
import DataTableColumnModel from "@/components/Bread/DataTableColumnModel";
import DataTableDialogDestroy from "@/components/Bread/DataTableDialogDestroy";
import DataTableDialogRestore from "@/components/Bread/DataTableDialogRestore";
import DataTableDialogDelete from "@/components/Bread/ClassroomOnEvent/DataTableDialogDelete";
import DataTableDialogDeleteSchoolYear from "@/components/Moderator/Event/SchoolYear/DataTableDialogDeleteSchoolYear";
import Notice from "@/components/Bread/Event/Notice/Notice";
import FestivalNotice from "@/components/Moderator/Festival/Notice/FestivalNotice";
import FestivalMessage from "@/components/Moderator/Festival/Invitation/Message";
import Pass from "@/components/Bread/Event/Pass/Pass";
import Password from "@/components/Bread/Event/Password/Dialog";
import FormLogo from "@/components/Moderator/School/FormLogo";
import { mapState } from "vuex";
export default {
  components: {
    DataTableButtonAccountSelect,
    DataTableColumnInviteMenu,
    DataTableColumnBoolean,
    DataTableColumnMessage,
    DataTableColumnModel,
    DataTableDialogDestroy,
    DataTableDialogRestore,
    DataTableDialogDelete,
    DataTableDialogDeleteSchoolYear,
    Password,
    Pass,
    FormLogo,
    FestivalMessage,
    Notice,
    FestivalNotice,
  },
  computed: mapState({
    //
  }),
  props: [
    "bread",
    "api",
    "items",
    "role",
    "model",
    "search",
    "headers",
    "expandable",
    "isSearchable",
    "parent",
    "modelParent",
    "grandParent",
    "modelGrandParent"
  ],
  data: () => ({
     urlGame:process.env.VUE_APP_URL_GAME,
     downloadUrl:null,
     logoUrl:null,
     replaceLogoUrl:null,
     schoolLogo:true,
    //BOC:[model]
    models: [],
    //EOC
    //BOC
    expanded: [],
    //EOC
    //BOC:[destroy]
    dialogDestroy: false,
    //EOC
    datasetSchoolYears: datasetSchoolYears,
  }),
  created() {
    //BOC:[model]
    Object.keys(models).forEach((e) => {
      this.models.push(this.$_ucfirst(e));
    });
    //EOC
  },
  beforeMount() {
    //
  },
  methods: {
    //
    refetchClassroom() {
      this.$emit("refetch");
    },
    //
      //
      refetchSchoolYear() {
      this.$emit("refetch");
    },
    //
     //
    refetchInfo() {
      this.$emit("refetch");
    },
    //
    callbackSuccess(res){
      this.replaceLogoUrl = null
      this.schoolLogo = true
      this.logoUrl = res.data.logoUrl ||  res.data.imageUrl
    },
    replaceLogo(){
      //this.logoUrl=null
      this.replaceLogoUrl = null
 this.schoolLogo = false
    },
    async downloadImage(url, name){
      var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = name+'.png';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    xhr.open('GET', url);
    xhr.send();
    },
    callbackPreview(url){
this.replaceLogoUrl = url
    },
    customFilter(value, search) {
      if(value == null || search == null) {
        return false
      }
      switch(typeof value) {
        case 'boolean':
          return false
        case 'object':
          if(value.translatableName) {
            return JSON.parse(value.translatableName)[this.$_getLocale()].toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
          }
          else if(value.name) {
            return value.name.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
          }
          break
        default:
          return value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1

      }
      return false
    }
  },
};
</script>
<style>
.remove{
  cursor: pointer;
   text-transform: uppercase;
}
</style>